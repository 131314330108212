*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    height: 100vh;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

#loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #3d3d3d; /* Grey */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
}

#loaderGIF {
    width: 200px;
    height: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}